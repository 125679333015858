import * as cx from 'classnames';
import { parse } from 'query-string';
import * as React from 'react';
import { Form, InputGroup, OverlayTrigger } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import { useAuth } from 'common/hooks/useAuth';
import { usePrevious } from 'common/hooks/usePrevious';

import { Avatar } from 'cms/components/Avatar';
import { ABOUT_URL, BLOG_URL, PP_URL, TC_URL } from 'cms/constants';
import { DISCOVER$, HOME$, SETTINGS$, SETTINGS_USERS$ } from 'cms/routes';

import { ExportsMenu } from '../ExportsMenu';
import * as s from './Header.m.less';

export const Header: React.FC = function Header() {
  const { profile, logout } = useAuth();
  const [show, setShow] = React.useState(false);

  const history = useHistory();
  history.listen(() => setShow(false));

  const location = useLocation();
  const [query, setQuery] = React.useState<string>(null);
  const org = profile?.org || profile?.conference;

  const inputRef = React.useRef<HTMLInputElement>();

  const [q, back] = React.useMemo<[string, string]>(
    () => {
      const search = parse(location.search);
      return [search.query as string, search.back as string];
    },
    [location.search],
  );

  React.useEffect(() => {
    if (q && q !== query) {
      setQuery(q);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  const prev = usePrevious(query);
  React.useEffect(() => {
    if (prev !== query && query === '' && prev && q) {
      history.push(DISCOVER$.buildPath({ section: What.MOMENTS }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleQuery = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(value);
    },
    [],
  );

  const handleBlur = React.useCallback(() => {
    if (query === '') {
      setQuery(null);
    }
  }, [query]);

  const handleToggle = React.useCallback(() => {
    if (query === null) {
      setQuery('');
      inputRef.current?.focus();
    }
  }, [query]);

  const handleSearch = React.useCallback((e: React.FormEvent) => {
    e.preventDefault();

    if (query.length !== 0) {
      history.push(DISCOVER$.buildPath({ queryParams: { query } }));
    }
  }, [history, query]);

  const renderProfileMenu = React.useCallback(({
    className, show: _1, arrowProps: _2, ...props
  }): JSX.Element => (
    <div className={cx(s.profileMenu, className)} {...props}>
      <ul>
        <li className={s.name}>{profile.full_name}</li>
        <li className={s.email}>{profile.email}</li>
      </ul>
      <ul>
        <li><Link to={SETTINGS$.buildPath()}>Account</Link></li>
        {/* <li><Link to="/">Knowledge Center</Link></li> */}
        <li><Link to="/" onClick={logout}>Sign Out</Link></li>
      </ul>
      <ul>
        <li>
          <a href={ABOUT_URL} target="_blank" rel="noreferrer">About</a>
        </li>
        <li>
          <a href={BLOG_URL} target="_blank" rel="noreferrer">Blog</a>
        </li>
        <li>
          <a href={TC_URL} target="_blank" rel="noreferrer">Terms and Conditions</a>
        </li>
        <li>
          <a href={PP_URL} target="_blank" rel="noreferrer">Privacy Policy</a>
        </li>
        <li>
          Connect with us:
          <ul className={s.social}>
            <li>
              <a
                href="https://www.linkedin.com/company/weplayed/"
                target="_blank"
                rel="noreferrer"
                className={s.linkedin}
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/weplayed/"
                className={s.instagram}
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/weplayed"
                className={s.twitter}
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/weplayedinc/"
                className={s.facebook}
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  ), [logout, profile?.email, profile?.full_name]);

  return (
    <header className={s.root}>
      {back && (
        <Link className={cx(s.menu, s.back)} to={back}>Back</Link>
      )}
      <Link
        className={s.logo}
        to={HOME$.buildPath()}
      >
        <span>WePlayed Sports</span>
      </Link>
      {org ? (
        <Link
          className={s.school}
          to={HOME$.buildPath()}
        >
          {org.logo && <img src={org.logo} alt={org.name} />}
          {org.name}
        </Link>
      ) : (
        <Link
          className={s.mc}
          to={HOME$.buildPath()}
        >
          Mission Control
        </Link>
      )}
      {profile && (
        <>
          <div className={s.container}>
            {profile.org && (
              <>
                <form
                  className={cx(s.form, query !== null && s.visible)}
                  onSubmit={handleSearch}
                >
                  <InputGroup>
                    <Form.Control
                      onBlur={handleBlur}
                      onChange={handleQuery}
                      placeholder="Search"
                      ref={inputRef}
                      type="search"
                      value={query || ''}
                    />
                    <InputGroup.Append>
                      <Button
                        onClick={handleSearch}
                        type="submit"
                        variant="primary"
                      >
                        Find!
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </form>
                <button
                  aria-hidden
                  className={s.search}
                  onClick={handleToggle}
                  type="button"
                >
                  Search
                </button>
              </>
            )}
          </div>
          <ExportsMenu className={s.downloads} />
          <a
            className={s.help}
            href={process.env.HELP_CENTER_URL}
            rel="noreferrer"
            target="_blank"
          >
            Help Center
          </a>
          {profile?.can_manage_users && (
            <Link to={SETTINGS_USERS$.buildPath()} className={s.settings}>
              Settings
            </Link>
          )}
          <div className={s.divider} aria-hidden />
          <OverlayTrigger
            onToggle={setShow}
            overlay={renderProfileMenu}
            placement="bottom-end"
            rootClose
            show={show}
            trigger="click"
          >
            <button type="button" className={s.profile}>
              <Avatar user={profile} />
            </button>
          </OverlayTrigger>
        </>
      )}
    </header>
  );
};
