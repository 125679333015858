import * as React from 'react';
import { Form, Table } from 'react-bootstrap';
import ClipboardButton from 'react-clipboard.js';
import Select from 'react-select/creatable';
import { LinkType, Moment, What } from 'weplayed-typescript-api';

import { Button } from 'common/components/Button';
import { useApplication } from 'common/hooks/useApplication';
import { usePrevious } from 'common/hooks/usePrevious';
import { EventContext, EVENTS, weplayedEvent } from 'common/utils/events';
import { pkify } from 'common/utils/helpers';
import { format } from 'common/utils/strings';
import { isEmailValid } from 'common/utils/validations';

import { Avatar } from 'cms/components/Avatar';
import { EmbedMomentForm } from 'cms/components/EmbedMomentForm';
import { useRecipients } from 'cms/hooks/useRecipients';
import { Recipient } from 'cms/hooks/useRecipients/types';
import { useShare } from 'cms/hooks/useShare';

import { CopyLinkForm, LinkTypeButton } from '../CopyLinkDropdown';
import { Lists } from '../Entities';
import { PageSidebar, PageSidebarSection } from '../PageSidebar';
import { SectionNav, SectionNavItem } from '../SectionNav';
import {
  EmailSuccessMessage, EmbedSuccessMessage, messages,
} from './constants';
import { DirectShare } from './DirectShare';
import * as s from './ShareSidebar.m.less';
import { RecipientWithLink, ShareData, ShareSidebarProps, Tabs } from './types';
import { isTypeOf, toData, toLabel } from './utils';
import { WebShare } from './WebShare';

export const ShareSidebar = function ShareSidebar<
  T extends What
>({
  item,
  items,
  onClose,
  type,
  user,
}: ShareSidebarProps<T>): JSX.Element {
  const { broadcast } = useApplication();

  const [context, setContext] = React.useState<ShareData<T>>(null);
  const [show, setShow] = React.useState(false);
  const [recipients, setRecipients] = React.useState<RecipientWithLink[]>([]);
  const [query, setQuery] = React.useState<string>();
  const [message, setMessage] = React.useState<string>('');
  const [embed, setEmbed] = React.useState<string>();
  const [tabs, setTabs] = React.useState<Tabs[]>([]);
  const [tab, setTab] = React.useState<Tabs>();

  // Reset message and recipients on show
  React.useEffect(() => {
    if (show) {
      setRecipients([]);
      setMessage('');
    }
  }, [show]);

  // Recipients data
  const data = useRecipients({
    enabled: Boolean(user?.org) && show,
    query,
  });

  // Share hook
  const {
    email: [shareEmail, { isLoading }],
    social,
  } = useShare({
    context: EventContext.ADMIN,
    items: context?.items,
    orgId: user?.org?.pk,
    type: context?.type,
    userId: user?.pk,
  });

  // Emails validator
  const handleValidEmail = React.useCallback(
    (email) => isEmailValid(email) && !recipients.find((i) => i.email === email),
    [recipients],
  );

  // Add user to recipients list
  const handleAddRecipient = React.useCallback((recipient: Recipient): void => {
    setRecipients(recipients.concat([{
      ...recipient,
      link: recipient.pk ? LinkType.PRIVATE : LinkType.PUBLIC,
    }]));
  }, [recipients]);

  // Remove recipient from list
  const handleRemoveRecipient = React.useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>): void => {
      const idx = parseInt(currentTarget.getAttribute('data-idx'), 10);
      setRecipients([...recipients.slice(0, idx), ...recipients.slice(idx + 1)]);
    },
    [recipients],
  );

  // Change link type, private or public
  const handleLinkType = React.useCallback(
    (recipient: RecipientWithLink, link: LinkType): void => {
      setRecipients(recipients.map((r) => (r.pk && r.pk === recipient.pk ? { ...r, link } : r)));
    },
    [recipients],
  );

  // Send callback
  const handleSend = React.useCallback(() => {
    shareEmail({
      message,
      recipients: recipients.map(
        ({ email, pk: user_id, link }) => (
          (user_id ? { user_id, link } : { email, link })),
      ),
      type: context.type,
    });
  }, [context?.type, message, recipients, shareEmail]);

  // Shows notification about embed code copy
  // as well as send event to track
  const handleCopyEmbed = React.useCallback(() => {
    broadcast('success', EmbedSuccessMessage);

    weplayedEvent({
      context: EventContext.ADMIN,
      moment_id: context?.items[0].pk,
      type: EVENTS.MOMENT_EMBED,
    });
  }, [broadcast, context?.items]);

  // in order not to reset tab/tabs content on reload items from
  // cache we use textual representation of the content to
  // share
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const $itemPks = `${type || ''}:${item?.pk || items?.map(pkify).join('-')}`;

  // Calculation of available tabs, initial tab and toggles component visibility
  React.useEffect(() => {
    if (type && (item || items?.length)) {
      const $items = item ? [item] : items;
      const supports = [What.COLLECTIONS, What.MOMENTS].includes(type);

      setContext({ type, items: $items });
      setShow(true);

      const $tabs = [
        supports && user?.pk && Tabs.EMAIL,
        supports && $items.length === 1 && Tabs.SOCIAL,
        // show embed only for moments and only if one moment is provided
        type === What.MOMENTS
          && $items.length === 1
          && ($items[0] as Moment).can_embed
          && Tabs.EMBED,
      ].filter(Boolean);
      setTabs($tabs);
      setTab($tabs[0]);
    } else {
      setShow(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, $itemPks, user?.pk]);

  // Show notification of success email sharing
  const prevIsLoading = usePrevious(isLoading);
  React.useEffect(() => {
    if (prevIsLoading && !isLoading) {
      onClose();
      broadcast('success', EmailSuccessMessage);
    }
  }, [isLoading, onClose, prevIsLoading, broadcast]);

  return (
    <PageSidebar
      className={s.root}
      close
      show={show}
      onClose={onClose}
      title="Share"
    >
      <PageSidebarSection>
        <SectionNav>
          {tabs.includes(Tabs.EMAIL) && (
            <SectionNavItem
              active={tab === Tabs.EMAIL}
              onClick={(): void => setTab(Tabs.EMAIL)}
            >
              Email
            </SectionNavItem>
          )}
          {tabs.includes(Tabs.SOCIAL) && (
            <SectionNavItem
              active={tab === Tabs.SOCIAL}
              onClick={(): void => setTab(Tabs.SOCIAL)}
            >
              Social
            </SectionNavItem>
          )}
          {tabs.includes(Tabs.EMBED) && (
            <SectionNavItem
              active={tab === Tabs.EMBED}
              onClick={(): void => setTab(Tabs.EMBED)}
            >
              Embed
            </SectionNavItem>
          )}
        </SectionNav>
      </PageSidebarSection>
      <PageSidebarSection noBorder>
        {context?.items?.length > 1 ? (
          format(messages[context?.type].pluralItems, context?.items.length)
        ) : (
          ((isTypeOf(context, What.MOMENTS) && (
            <Lists.Moments
              created={false}
              edited={false}
              header={false}
              items={context.items}
            />
          )) || (isTypeOf(context, What.COLLECTIONS) && (
            <Lists.Collections
              created={false}
              edited={false}
              header={false}
              items={context?.items}
            />
          )))
        )}
      </PageSidebarSection>
      {tab === Tabs.EMAIL && (
        <>
          <PageSidebarSection title="Add recipients">
            {user?.org && (
              <span className={s.help}>
                * Can be members of your team or people outside of your organization
              </span>
            )}
            <Select
              allowCreateWhileLoading
              className="react-select__container"
              classNamePrefix="react-select"
              createOptionPosition="first"
              getNewOptionData={toData}
              getOptionLabel={toLabel}
              isClearable
              isLoading={data.isLoading}
              isValidNewOption={handleValidEmail}
              onChange={handleAddRecipient}
              onInputChange={setQuery}
              options={data.data?.filter(({ pk }) => !recipients.find((i) => i.pk === pk))}
              value={null}
            />

            {recipients?.length ? (
              <Table className={s.table}>
                <tbody>
                  {recipients.map((recipient, idx) => (
                    <tr key={recipient.pk || recipient.email}>
                      <td className={s.tdAvatar}>
                        {recipient.pk ? <Avatar user={recipient} className={s.avatar} /> : ' '}
                      </td>
                      <td>
                        <button
                          className={s.remove}
                          data-idx={idx}
                          onClick={handleRemoveRecipient}
                          type="button"
                        >
                          {recipient.full_name || recipient.email}
                        </button>
                      </td>
                      {user?.org && (
                        <td className={s.tdLink}>
                          <LinkTypeButton
                            disabled={!recipient.pk}
                            onChange={(link): void => handleLinkType(recipient, link)}
                            value={recipient.link}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </PageSidebarSection>
          <PageSidebarSection
            noBorder
            title={(
              <>
                Add a message
                <span data-size="smaller">(optional)</span>
              </>
            )}
          >
            <Form.Control
              as="textarea"
              onChange={({ currentTarget }): void => setMessage(currentTarget.value)}
              rows={3}
              value={message || ''}
            />
          </PageSidebarSection>
          <PageSidebarSection buttons rest>
            <Button
              disabled={!recipients.length || isLoading}
              loading={isLoading}
              onClick={handleSend}
              type="button"
            >
              Send
            </Button>
            <Button
              onClick={onClose}
              type="button"
              variant="default"
            >
              Close
            </Button>
          </PageSidebarSection>
        </>
      )}
      {tab === Tabs.SOCIAL && (
        <>
          <PageSidebarSection>
            {user.can_direct_share && context?.items?.length === 1 && (
              isTypeOf(context, What.COLLECTIONS) || isTypeOf(context, What.MOMENTS)
            )
              ? <DirectShare<T> context={context} onClose={onClose} user={user} />
              : <WebShare social={social} />}
          </PageSidebarSection>
          <PageSidebarSection buttons rest>
            <Button
              onClick={onClose}
              type="button"
              variant="default"
            >
              Close
            </Button>
          </PageSidebarSection>
        </>
      )}
      {tab === Tabs.EMBED && (
        <>
          <PageSidebarSection>
            <EmbedMomentForm
              uid={context.items[0].pk}
              onCode={setEmbed}
            />
          </PageSidebarSection>
          <PageSidebarSection buttons rest>
            <Button
              as={ClipboardButton}
              data-clipboard-text={embed}
              onSuccess={handleCopyEmbed}
              disabled={!embed}
              loading={!embed}
            >
              Copy code
            </Button>
            <Button
              onClick={onClose}
              type="button"
              variant="default"
            >
              Close
            </Button>
          </PageSidebarSection>
        </>
      )}
      {context?.items?.length === 1 && tab !== Tabs.EMBED && (
        <PageSidebarSection title="Copy Link">
          <CopyLinkForm
            className={s.copy}
            context={EventContext.ADMIN}
            item={context.items[0]}
            orgId={user?.org?.pk}
            type={context.type}
            userId={user?.pk}
          />
        </PageSidebarSection>
      )}
    </PageSidebar>
  );
};
